@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/icomoon.eot?6e245d');
  src: url('../assets/fonts/icomoon.eot?6e245d#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/icomoon.ttf?6e245d') format('truetype'),
    url('../assets/fonts/icomoon.woff?6e245d') format('woff'),
    url('../assets/fonts/icomoon.svg?6e245d#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.2rem;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 1155px) {
  [class^='icon-'],
  [class*=' icon-'] {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 599px) {
  [class^='icon-'],
  [class*=' icon-'] {
    font-size: 2.5rem;
  }
}

.icon-door-closed:before {
  content: '\e91b';
}
.icon-door-open:before {
  content: '\e91c';
}
.icon-box-closed:before {
  content: '\e927';
}
.icon-box-open:before {
  content: '\e928';
}
.icon-android:before {
  content: '\e901';
}
.icon-ios:before {
  content: '\e906';
}
.icon-unity:before {
  content: '\e907';
}
.icon-unreal:before {
  content: '\e90d';
}
.icon-broadcast:before {
  content: '\e908';
}
.icon-graph:before {
  content: '\e909';
}
.icon-network:before {
  content: '\e90a';
}
.icon-people:before {
  content: '\e90b';
}
.icon-time:before {
  content: '\e90c';
}
.icon-alert-circle:before {
  content: '\e911';
}
.icon-arrow-right:before {
  content: '\e913';
}
.icon-chevron-down:before {
  content: '\e916';
}
.icon-chevron-left:before {
  content: '\e917';
}
.icon-chevron-right:before {
  content: '\e918';
}
.icon-chevron-up:before {
  content: '\e919';
}
.icon-image:before {
  content: '\e921';
}
.icon-menu:before {
  content: '\e90e';
}
.icon-package:before {
  content: '\e926';
}
.icon-plus:before {
  content: '\e902';
}
.icon-search:before {
  content: '\e90f';
}
.icon-x:before {
  content: '\e915';
}
.icon-warningreport_problem:before {
  content: '\e903';
}
.icon-pause:before {
  content: '\e904';
}
.icon-play_arrow:before {
  content: '\e905';
}
.icon-filter_list:before {
  content: '\e920';
}
.icon-save_alt:before {
  content: '\e900';
}
.icon-file_downloadget_app:before {
  content: '\e923';
}
.icon-filter_none:before {
  content: '\e912';
}
.icon-tune:before {
  content: '\e922';
}
.icon-drop-down:before {
  content: '\e924';
}
.icon-drop-up:before {
  content: '\e925';
}
.icon-check:before {
  content: '\e910';
}
.icon-assignment:before {
  content: '\e91d';
}
.icon-info:before {
  content: '\e91a';
}
.icon-launchopen_in_new:before {
  content: '\e914';
}
.icon-gavel:before {
  content: '\e91e';
}
.icon-calendar_today:before {
  content: '\e91f';
}
.icon-appleinc:before {
  content: '\eabe';
}
